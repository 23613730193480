//assets
import image from "../../assets/images/modal-size/modal-size__img_1.png";

export const gendersClothesWomen = {
  title: "common.gendersClothes.women",
  image: image,
  titlesColumns: [
    {
      id: 0,
      text: "clothingSize.ukrSize",
    },
    {
      id: 1,
      text: "common.sizeOptions.chest",
      additional: ", см",
    },
    {
      id: 2,
      text: "common.sizeOptions.waist",
      additional: ", см",
    },
    {
      id: 3,
      text: "common.sizeOptions.thigh",
      additional: ", см",
    },
  ],
  sizesMass: [
    {
      id: 0,
      sizes: ["40", "78-82", "60-64", "86-90"],
    },
    {
      id: 1,
      sizes: ["42", "82-86", "64-68", "90-94"],
    },
    {
      id: 2,
      sizes: ["44", "86-90", "68-72", "94-98"],
    },
    {
      id: 3,
      sizes: ["46", "90-94", "72-76", "98-102"],
    },
    {
      id: 4,
      sizes: ["48", "94-98", "76-80", "102-106"],
    },
    {
      id: 5,
      sizes: ["50", "98-102", "80-84", "106-110"],
    },
    {
      id: 6,
      sizes: ["52", "102-106", "84-88", "110-114"],
    },
    {
      id: 7,
      sizes: ["54", "106-110", "88-92", "114-118"],
    },
    {
      id: 8,
      sizes: ["56", "110-114", "92-96", "118-122"],
    },
    {
      id: 9,
      sizes: ["58", "114-118", "96-100", "122-126"],
    },
    {
      id: 10,
      sizes: ["60", "118-122", "100-104", "126-130"],
    },
  ],
  text: `
          Вам понадобится провести измерения с помощью сантиметровой ленты. При
          измерении обхвата груди лента должна плотно прилегать к телу, спереди
          проходить по наиболее выступающим точкам, сбоку через подмышечные
          впадины, сзади обхватывая лопатки. Обхват талии измеряется строго
          горизонтально по самой узкой части тела, проходя через самую
          выступающую точку живота. При измерении обхвата бедер лента должна
          находиться горизонтально, проходя посредине бедра и сзади по наиболее
          выступающим точкам ягодиц.
        `,
};

export const gendersClothesMen = {
  title: "common.gendersClothes.men",
  image: image,
  titlesColumns: [
    {
      id: 0,
      text: "clothingSize.ukrSize",
    },
    {
      id: 1,
      text: "common.sizeOptions.chest",
      additional: ", см",
    },
    {
      id: 2,
      text: "common.sizeOptions.waist",
      additional: ", см",
    },
    {
      id: 3,
      text: "common.sizeOptions.thigh",
      additional: ", см",
    },
  ],
  sizesMass: [
    {
      id: 0,
      sizes: ["44", "86-89", "74-77", "36-37"],
    },
    {
      id: 1,
      sizes: ["46", "90-93", "78-81", "37-38"],
    },
    {
      id: 2,
      sizes: ["48", "94-97", "82-85", "38-39"],
    },
    {
      id: 3,
      sizes: ["50", "98-101", "86-89", "39-40"],
    },
    {
      id: 4,
      sizes: ["52", "102-105", "90-93", "40-41"],
    },
    {
      id: 5,
      sizes: ["54", "106-109", "94-97", "41-42"],
    },
    {
      id: 6,
      sizes: ["56", "110-113", "98-101", "42-43"],
    },
    {
      id: 7,
      sizes: ["58", "114-117", "102-105", "43-44"],
    },
  ],
  text: `
    Для точного определения размера необходимо измерить: обхват груди, обхват талии и обхват шеи.
    Измерения обхвата груди проводится через подмышечные впадины и наиболее выступающие точки груди.
    Обхват талии измеряется по самому узкому месту. Обхват шеи измеряется по основанию шеи.
  `,
};

export const gendersClothesChildren = {
  title: "common.gendersClothes.kids",
  image: image,
  titlesColumns: [
    {
      id: 0,
      text: "common.sizeOptions.age",
    },
    {
      id: 1,
      text: "common.sizeOptions.height",
      additional: ", см",
    },
    {
      id: 2,
      text: "common.sizeOptions.chest",
      additional: ", см",
    },
    {
      id: 3,
      text: "common.sizeOptions.waist",
      additional: ", см",
    },
  ],
  sizesMass: [
    {
      id: 0,
      sizes: ["2-3", "92-98", "52-54", "50-51"],
    },
    {
      id: 1,
      sizes: ["3-4", "98-104", "54-56", "51-52"],
    },
    {
      id: 2,
      sizes: ["4-5", "104-110", "56-58", "52-53"],
    },
    {
      id: 3,
      sizes: ["5-6", "110-116", "58-60", "53-54"],
    },
    {
      id: 4,
      sizes: ["6-7", "116-122", "60-62", "54-55"],
    },
    {
      id: 5,
      sizes: ["7-8", "122-128", "62-64", "55-56"],
    },
    {
      id: 6,
      sizes: ["8-9", "128-134", "64-66", "56-57"],
    },
    {
      id: 7,
      sizes: ["9-10", "134-140", "66-68", "57-58"],
    },
  ],
  text: `
    Для визначення розміру дитячого одягу необхідно виміряти зріст дитини, обхват грудей та обхват талії.
    Зріст вимірюється від маківки до п'ят. Обхват грудей вимірюється по найбільш виступаючих точках грудей.
    Обхват талії вимірюється по найвужчому місцю.
  `,
};
