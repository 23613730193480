import { useTranslation } from "react-i18next";

//styles
import s from "./ModalClothingSize.module.scss";

//components
import ModalClose from "../ModalComponents/ModalClose/ModalClose";

//ui
import TitleWithLine from "../../TitleWithLine/TitleWithLine";

//db
import {
	gendersClothesWomen,
	gendersClothesMen,
	gendersClothesChildren,
} from "../../../../db/Modals/ModalClothingSize";

//hooks
import { useAppSelector } from "../../../../hooks/redux";

const ModalClothingSize = () => {
	const { t } = useTranslation();
	const { productBack } = useAppSelector((state) => state.productCard);

	// Helper function to find category by name in the parent chain
	const findCategoryInParentChain = (
		category: any,
		targetName: string,
	): boolean => {
		if (!category) return false;
		if (category.name === targetName) return true;
		return category.parent
			? findCategoryInParentChain(category.parent, targetName)
			: false;
	};

	const getSizeChart = () => {
		if (productBack) {
			console.log("productBack", productBack);
		}
		if (!productBack) {
			console.log("No productBack data, defaulting to women's sizes");
			return gendersClothesWomen;
		}

		console.log("Product data:", {
			specifications: productBack.specifications,
			categoryName: productBack.category?.name,
			productName: productBack.name,
		});

		// Check if it's a men's product
		const isMensProduct = findCategoryInParentChain(
			productBack.category,
			"Чоловікам",
		);

		if (isMensProduct) {
			return gendersClothesMen;
		}

		// Check if it's any children's category
		const isChildrenProduct =
			findCategoryInParentChain(productBack.category, "Хлопчикам") ||
			findCategoryInParentChain(productBack.category, "Дівчаткам") ||
			findCategoryInParentChain(productBack.category, "Дітям");

		if (isChildrenProduct) {
			return gendersClothesChildren;
		}

		// Default to women's sizes
		console.log(
			"No specific category detected, defaulting to women's sizes",
		);
		return gendersClothesWomen;
	};

	const sizeChart = getSizeChart();

	return (
		<div className={s.root}>
			<ModalClose />
			<div className={s.wrapperTitle}>
				<TitleWithLine
					title={t("clothingSize.title")}
					marginTop={{ xl: "20px" }}
					fontSize={{ xl: "18px", lg: "14px" }}
					widthLine={{ xl: "60px", lg: "28px" }}
					heightLine={{ xl: "3px", lg: "2px" }}
				/>
			</div>
			{sizeChart?.title && (
				<TitleWithLine
					title={t(sizeChart.title)}
					marginTop={{ xl: "20px" }}
					fontSize={{ xl: "16px" }}
					widthLine={{ xl: "30px" }}
					heightLine={{ xl: "2px" }}
				/>
			)}
			<div className={s.table}>
				{sizeChart?.titlesColumns?.length > 0 && (
					<div className={s.titleList}>
						{sizeChart?.titlesColumns?.map((item: any) => (
							<div
								className={s.titleListItem}
								key={item.id}
							>
								{t(item.text)}
								{item.additional && item.additional}
							</div>
						))}
					</div>
				)}
				{sizeChart?.sizesMass?.length > 0 && (
					<ul className={s.list}>
						{sizeChart?.sizesMass?.map((item: any) => (
							<li
								className={s.listItem}
								key={item?.id}
							>
								{item?.sizes?.map(
									(size: string, index: number) => (
										<span key={index}>{size}</span>
									),
								)}
							</li>
						))}
					</ul>
				)}
			</div>
			<div className={s.wrapperSubtitle}>
				<TitleWithLine
					title={t("clothingSize.hint")}
					fontSize={{ xl: "16px" }}
					widthLine={{ xl: "30px" }}
					heightLine={{ xl: "2px" }}
				/>
			</div>

			<div className={s.content}>
				{sizeChart.text && (
					<div className={s.text}>{sizeChart.text}</div>
				)}
				{sizeChart.image && (
					<div className={s.wrapperImg}>
						<img
							src={sizeChart.image}
							alt="+"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ModalClothingSize;
